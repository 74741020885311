.triangle {
    position: relative;
    background: #2c3e50;
    height: 40vh;
  }
  
  .triangle::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 100px 0 100px;
    border-color: #2c3e50 transparent transparent transparent;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
  }

  .equipos_text_style{
    text-align: center;
    color: white;
    font-size: 5rem;
    padding-top: 5rem;
  }





  