
.png {
    display: flex;
    justify-content: space-between;
}

.png_1, .png_2, .png_3, .png_4, .png_5 {
    text-align: center;
    margin-left: 1rem;
}

.png_5 {
    margin-right: 1rem;
}

.sombra {
    padding: 2rem;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    box-shadow: 1px 4px 19px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 1px 4px 19px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 4px 19px 0px rgba(0,0,0,0.75);
}

.pngPrincipal {
  color: black;
  padding: 0px 5px;
}

.text_style_icon{
    font-size: 1.3rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
   
}

@media(min-width: 360px) {
    .iconsMedio {
        display: grid;
        grid-template-columns: repeat(2, 3fr);
  
    }
    .png_5 {
      grid-row: 3 / 4;
      grid-column: 1 / 3;
    }
  
  }

@media (min-width:768px){
    .iconsMedio {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }
}