.btn-wsp {
    position: fixed;
    width: 65px;
    height: 65px;
    line-height: 55px;
    bottom: 30px;
    right: 30px;
    background: #0df053;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 0px 1px 10px rgb(0,0,0,0.3);
    z-index: 100;
    display: flex;
    justify-content: center;
}
.btn-wsp:hover {
    text-decoration: none;
    color: #0df053;
    background: #fff;
}
