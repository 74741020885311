.parallax {
    /* The image used */
    background-image: url(../../Assets/Img/Hero/xeroxp.jpg);
  
    /* Set a specific height */
    min-height: 500px; 
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }  

  @media(min-width: 1200px) {
    .parallax {
        background-image: url(../../Assets/Img/Hero/xerox3.png);
    }
  }